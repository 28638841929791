<template>
  <v-main>
    <v-card
      class="ma-2"
      flat
    >
      <v-card-title>{{ ratingName }}</v-card-title>
      <v-card-subtitle>
        Установите параметры выборки. По умолчанию показывается последний месяц.
      </v-card-subtitle>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="4"
          >
            <DatePicker
              v-model="date__gte"
              label="Дата начала"
              :max="date__lte"
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <DatePicker
              v-model="date__lte"
              label="Дата окончания"
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-select
              v-model="country"
              :items="getCountryList"
              label="Страна"
              hide-details
            />
          </v-col>
        </v-row>
        <v-data-table
          :headers="getHeaders"
          :items="data.data"
          item-key="id"
          disable-pagination
          hide-default-footer
          disable-sort
          :loading="loading"
        >
          <template #[`item.proceeds`]="{ item }">
            {{ item.proceeds | currency(country) }}
          </template>
          <template #[`item.cost_price`]="{ item }">
            {{ item.cost_price | currency(country) }}
          </template>
          <template #[`item.margin`]="{ item }">
            {{ item.margin | currency(country) }}
          </template>
        </v-data-table>
      </v-container>
    </v-card>
  </v-main>
</template>

<script>
import finance from "@/api/finance";
import DatePicker from "@/components/DatePicker";
import {mapGetters} from "vuex";

export default {
  name: "RatingView",
  components: {
    DatePicker,
  },
  data: () => ({
    loading: true,
    country: "RU",
    date__gte: new Date(new Date().setDate(1)).toISOString().substr(0, 10),
    date__lte: new Date().toISOString().substr(0, 10),
    data: {},
  }),
  computed: {
    ...mapGetters("clients", ["getCountryList",]),
    ...mapGetters("auth", ["getUseCountry", "isWholeSaleGroup", "isSuperUser"]),
    ratingName() {
      return 'Рейтинг магазинов'
    },
    getHeaders() {
      let headers = [
          {text: "", value: "position", width: "5"},
          {text: "Город", value: "city"},
          {text: "Локация", value: "name"},
          {text: "Площадь", value: "area"},
          {text: "Компания", value: "company", isSuperUser: true},
          {text: "Выручка", value: "proceeds"},
          {text: "Себестоимость", value: "cost_price"},
          {text: "Маржа", value: "margin"},
        ]
      headers = this.isSuperUser
        ? headers.filter((el) => el.isSuperUser !== false)
        : headers.filter((el) => el.isSuperUser !== true);
      return headers;
    }
  },
  watch: {
    date__gte(v) {
      if (Date.parse(v) <= Date.parse(this.date__lte)) this.updateFinance();
    },
    date__lte(v) {
      if (Date.parse(v) >= Date.parse(this.date__gte)) this.updateFinance();
      else this.date__gte = v;
    },
    country() {
      if (Date.parse(this.date__gte) <= Date.parse(this.date__lte))
        this.updateFinance();
    },
    getUseCountry(v) {
      this.country = v;
    },
  },
  created() {
    if (!this.isStaff) {
      this.country = this.getUseCountry;
    }
    this.updateFinance();
  },
  methods: {
    updateFinance() {
      this.loading = true;
      this.data = {};
      finance
        .getFinanceCompanyList({
          date__gte: this.date__gte,
          date__lte: this.date__lte,
          country: this.country,
        })
        .then((response) => {
          this.data = response.data;
          this.data.data = this.data.data
            // сортируем по прибыли для указания места
            .sort((a, b) =>
              Number.parseFloat(a.proceeds) > Number.parseFloat(b.proceeds)
                ? -1
                : Number.parseFloat(a.proceeds) < Number.parseFloat(b.proceeds)
                  ? 1
                  : 0
            )
            // переводим decimal во float, назначаем позицию в рейтинге, оформляем площадь
            .map((el, index) => ({
              ...el,
              position: index + 1,
              area: Number.isNaN(Number.parseFloat(el.area))
                ? "-"
                : `${Number.parseFloat(el.area).toLocaleString("ru")} м²`,
              proceeds: Number.parseFloat(el.proceeds),
              cost_price: Number.parseFloat(el.cost_price),
              margin: Number.parseFloat(el.margin),
            }));
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
